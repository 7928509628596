<template>
<!-- 补丁：last因为超出4个字数会乱 -->
    <div :class="dataSource.length<=4 ? 'wt-step': 'wt-step last'">
        <div class='horizontal' v-if="direction.toLocaleUpperCase() === 'H'">
            <div class="item-wrapper">
                <div class="item" :class="[{current:index<=active}]" v-for='(item, index) of dataSource' :key="index"
                    @tap="switchTap(index)">
                    <div class="line" v-if="index !=dataSource.length -1"></div>
                    <p v-if="dataSource.length<=5">{{item.step_name}}</p>
                    <div :class="[{active:index<=active},{'current_active':index == active}]" v-if="type=='default'">{{index + 1}}</div>
                    <div :class="[{active:index<=active},{'icon-check':index<=active}]" v-else></div>
                </div>
            </div>
        </div>
        <div class='vertical' v-if="direction.toLocaleUpperCase() === 'V'">
            <div class="item-wrapper">
                <div class="item" :class="[{current:item.active}]" v-for='(item, index) of dataSource' :key="index"
                    @tap="switchTap(index)">
                    <div class="left">
                        <div class="arc" :class="[{active:item.active}]" v-if="type=='default'">{{index + 1}}</div>
                        <div class="arc" :class="[{active:item.active},{'icon-check':item.active}]"
                            v-else-if="type=='check'"></div>
                        <div class="line" v-if="index !=dataSource.length -1"></div>
                    </div>
                    <div class="right">
                        <div class="title">{{item.text}}</div>
                        <div class="desc">{{item.desc}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'wt-step',
        props: {
            dataSource: {
                type: Array
            },
            type: {
                type: String,
                default: () => {
                    return 'default';
                }
            },
            direction: {
                type: String,
                default: () => {
                    return 'h';
                }
            },
            active: {
                type: Number,
                default: () => {
                    return 0;
                }
            }
        },
        data() {
            return {};
        },
        computed: {},
        methods: {
            // 点击事件
            switchTap(index) {
                this.$emit('handle', index);
            }
        },
        mounted() {}
    };
</script>

<style lang="scss">
.last{
    .item-wrapper{
        height: auto !important;
    }
    .item{
        &:last-child {
            p{
                // margin-top: 4.3rem !important;
                padding-right: 0.5rem;
                // position: relative;
                // margin-top: 2.3rem !important;
                // top:1rem;
            }
        }
    }
}
    .wt-step {
        .horizontal {
            // background: #fff;
            width: 100%;
            overflow: hidden;

            .item-wrapper {
                // background:palegoldenrod;
                display: flex;
                height: 3.5rem;

                .item {
                    text-align: center;
                    align-items: center;
                    flex: 1;
                    position: relative;
                    color: #333;
                    display: flex;
                    justify-content: space-around;
                    padding-bottom: 1.2rem;

                    &.current {
                        .line {
                            // background-color: #07c160;
                            background: #2EC6C4;
                        }
                    }

                    &:first-child {
                        margin-left: -0.5rem;
                    }

                    &:last-child {
                        margin-right: -0.5rem;
                    }

                    p {
                        font-size: 0.7rem;
                        // margin-bottom: 1.5rem;
                        // line-height: 2rem;
                        // margin-top: 3.3rem;
                        color: white;
                        height: 2rem;
                        position: relative;
                        top: 2rem;
                    }

                    &.current p {
                        color: #fff;
                    }

                    .line {
                        height: 0.1rem;
                        background: #ccc;
                        width: 100%;
                        position: absolute;
                        // top: 2.5rem;
                        border-radius: 0px;
                        left: 52%;
                        z-index: 1;
                    }

                    div {
                        background: #ccc;
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: 50%;
                        display: inline-block;
                        z-index: 2;
                        position: absolute;
                        // top: 2rem;
                        font-size: 0.7rem;
                        color: #fff;
                        line-height: 1.2rem;

                        &.active {
                            background-color: #2EC6C4;
                            color: #fff;
                            
                        }
                        &.current_active{
                            border: 2px solid #fff;
                            margin-top: -2px;
                        }
                    }
                }
            }
        }

        .vertical {
            background: #fff;
            width: 100%;
            overflow: hidden;

            .item-wrapper {
                .item {
                    color: #333;
                    display: flex;
                    min-height: 3.5rem;

                    &:last-child {
                        min-height: 0.5rem;
                    }

                    .left {
                        position: relative;
                        height: auto;
                        display: flex;
                    }

                    .right {
                        margin: 0 0.2rem;

                        .title {
                            font-size: 0.7rem;
                            margin-left: 0.2rem;
                            line-height: 1.2rem;
                        }

                        .desc {
                            font-size: 0.7rem;
                            color: #999;
                            margin: 0.3rem;
                        }
                    }

                    &.current p {
                        color: #07c160;
                    }

                    .arc {
                        background: #ccc;
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: 50%;
                        display: inline-block;
                        font-size: 0.7rem;
                        color: #a9a9a9;
                        line-height: 1.2rem;
                        text-align: center;
                    }

                    &.current {
                        .line {
                            background-color: #07c160;
                        }
                    }

                    .line {
                        padding: 0.2rem 0;
                        background: #ccc;
                        width: 0.1rem;
                        position: absolute;
                        height: calc(100% - 1.6rem);
                        box-sizing: border-box;
                        margin-top: 1.4rem;
                        left: 0.55rem;
                    }

                    div {
                        &.active {
                            background-color: #07c160;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>