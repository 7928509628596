<template>
    <div class="eac-add-agreement">
      <template v-if="!show">
        <div class="top">
          <Steps :dataSource="list" :active="list.length - 1" :class="short_name ==='xrb'?'steps last':'steps'"></Steps>
        </div>
        <div class="settle-content">
          <div class="title">选择领款方式</div>
          <div class="standard">请选择您的领款方式，平台将以您选定的方式进行打款。</div>
          <div class="settle-radio">
            <van-radio-group v-model="bankcard_pay">
              <van-cell-group>
                <van-cell v-for="(pitem, pindex) in bankcard_pay_list" :key="pindex" :title="pitem.settle_type" clickable @click="bankcard_pay = pitem.bankcard_pay">
                  <template #label>
                    打款方：{{pitem.settle_payer}}<br/>
                    财税服务率：{{pitem.tax_hint}} <van-icon v-if="pitem.tax_tips" name="question-o" size="14" @click.stop="openTips(pitem)" />
                  </template>
                  <template #icon>
                    <van-radio :name="pitem.bankcard_pay" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
          <div class="btn">
            <div class="agree-box" style="font-size: 12px; color: #787878;">选定后不可修改，请谨慎选择</div>
            <van-button round type="info" size="large" class="up" :disabled="disabled" @click="nextStep()">下一步</van-button>
          </div>
          <div class="goBack" @click="back">
            <img src="/static/goBack.png" />
          </div>
        </div>
      </template>
      <template v-else>
        <div id="demo"></div>
        <div class="btn">
            <van-button round type="info" size="large" class="up" :disabled="btnDisabled" @click="next">点击签名</van-button>
        </div>
      </template>
      
    </div>
</template>

<script>
    import Pdfh5 from "pdfh5";
    import "pdfh5/css/pdfh5.css"
    import Steps from '@/components/steps.vue'
    import {
        getSubProjectAgreement,
        projectDoctor,
        StepFour,
        getDoctorForm,
        getSubProjectConfig
    } from '@/api/api'
    import {
        mapActions,
        mapGetters,
        mapState
    } from 'vuex';
    import {
        appHost
    } from '@/utils/host'
    import {parseUrl} from '@/utils/utils'
    import {
        Toast, Dialog
    } from "vant";
    export default {
        components: {
            Steps,
        },
        data() {
            return {
                show: false,
                list: [{
                  step_name: '身份信息',
                }, {
                  step_name: '职称信息'
                }, {
                  step_name: '上传证件'
                }, {
                  step_name: '上传身份证'
                },{
                  step_name: '签署协议'
                }],
                disabled: false,
                bankcard_pay_list: [],
                bankcard_pay: null, // 领款方式
                project_doctor_id: null,
                baseFileUrl: appHost,
                pdfh5: null,
                checked: false,
                skipSign: false,
                btnDisabled: false,
                formList: [],
                short_name: "", //判断项目
                btn_text:"",
                dire: 'front', // 前进:front;后退:back
            }
        },
        async mounted() {
            this.short_name = this.basicInformation.projects[0].short_name
            this.project_doctor_id = this.$route.query.project_doctor_id
            if(['xrb', 'sshy'].includes(this.short_name)){
              this.list[4].step_name = `阅读知情同意书`
            }
            this.getData()
            // this.init()
        },
        computed: {
            ...mapGetters(['project_id','sub_id']),
            ...mapState(['imgList','idcardList','userId','basicInformation']), //获取身份证
        },
        methods: {
            async getData() {
              await getSubProjectConfig({id: this.sub_id}).then(res => {
                this.bankcard_pay_list = res
              })
              const data = await getDoctorForm({
                params: {
                  doctor_id: this.userId,
                  subproject_id: this.sub_id
                }
              })
              this.formList = data
              for (let i in this.formList) {
                if (this.formList[i].form.length < 1) {
                  this.list.splice(i, 1)
                }
              }
            },
            async init() {
                let res = await projectDoctor()
                if ((!res.hasOwnProperty('informed_consent') || !res.informed_consent) && res.sign_type == 0) {
                    this.skipSign = true
                    // console.log('this.skipSign', this.skipSign)
                } else if (res.hasOwnProperty('informed_consent') && res.informed_consent && res.sign_type == 0) {
                    this.skipSign = true
                }
                let {
                    items
                } = await getSubProjectAgreement()
                let pdffile = ''
                if (this.bankcard_pay == 2) {
                  pdffile = items[0].service_agreement_lwf
                } else if (this.bankcard_pay == 3) {
                  pdffile = items[0].service_agreement_fwf
                } else if (this.bankcard_pay == 4) {
                  pdffile = items[0].service_agreement_lhyg
                }
                this.pdfh5 = new Pdfh5("#demo", {
                    pdfurl: parseUrl(pdffile, this.baseFileUrl)
                });
                //监听完成事件
                this.pdfh5.on("complete", function (status, msg, time) {
                    console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
                })
            },
            openTips(item) {
              if (!item.tax_tips) return
              Dialog.alert({
                // title: '劳务费个人所得税的计算',
                title: item.tax_tips.split(':')[0],
                messageAlign: 'left',
                message: item.tax_tips.split(':')[1],
                confirmButtonText: '关闭',
              }).then(() => {
                // on close
              });
            },
            nextStep() {
              if (!this.bankcard_pay) {
                Dialog.alert({
                  title:"提示",
                  message:"请选择领款方式！",
                  confirmButtonColor: "#4B99FF",
                })
                return
              }
              this.show = true
              this.init()
            },
            next() {
              this.$router.push({
                name: 'EacAddSign',
                query: {
                  project_doctor_id: this.project_doctor_id,
                  bankcard_pay: this.bankcard_pay 
                }
              })
            },
            async back() {
                if (this.show) {
                  this.show = false
                  this.pdfh5.destroy()
                  return
                }
                if(this.formList[3].form.length > 0){
                    this.$router.replace({
                        path: '/add_idcard',
                        query: {
                            project_doctor_id: this.project_doctor_id,
                            dire: 'back'
                        }
                    })
                }else{
                    this.$router.replace({
                        name: 'EacAddSign',
                        query: {
                            project_doctor_id: this.project_doctor_id,
                            bankcard_pay: this.bankcard_pay,
                            dire: 'back'
                        }
                    })
                }
                
            }
        }

    }
</script>
<style lang="scss">
html, body, #app {
  height: 100%; box-sizing: border-box;
}
.eac-add-agreement {
  .settle-radio {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
<style lang="scss" scoped>
.eac-add-agreement {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 100px;

  .top {
    width: 100%;
    height: 97px;
    background-image: linear-gradient(to right, #5B9BF0, #3D87EA);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 14px;

    .steps {
      width: 100%;
      margin-bottom: 17px;
    }
  }
  .settle-content {
    height: 100%;
    width: 100%;
    padding: 9px 11px 0 9px;
    box-sizing: border-box;
    text-align: left;
    .title {
      font-size: 15px;
      color: #232323;
      margin-bottom: 13px;
    }

    .standard {
      color: #565656;
      font-size: 12px;
      margin-bottom: 3px;
    }
    .settle-radio {
      margin-top: 20px;
      .van-radio {
        align-items: flex-start;
        margin: 2px 8px 0 0;
      }
    }
  }
  .text {
    padding: 10px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-bottom: 70px;
  }
  .agree-box {
    line-height: 20px;
    padding: 10px;
    text-align: center !important;
    vertical-align: middle;
      font-size: 14px;
    .van-checkbox {
      justify-content: center;
    }
  }
}
</style>